<template>
  <datepicker
    v-bind="$attrs"
    v-on="listeners"
    class="input"
    :precision="0"
    :value="value"
  />
</template>

<script>
import Input from './Input';

export default {
  extends: Input,
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        closed: () => {
          this.$emit('blur');
        },
        input: (event) => {
          this.$emit('change', `${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()}`);
        },
      };
    },
  },
  mounted() {
    this.$el.parentElement.addEventListener('focus', () => {
      this.focus();
    }, true);

    this.$el.parentElement.addEventListener('blur', () => {
      this.blur();
    }, true);
  },
  methods: {
    blur() {
      this.$emit('blur');
    },
    focus() {
      this.$emit('focus');
    },
  },
};
</script>
